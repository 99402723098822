
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <p>Visit us on: </p>
          <a href={"https://github.com/kodewisch"}>Github</a>
      </header>
    </div>
  );
}

export default App;
